<template>
    <div>
        <subjective-paper-list :is-all="true" />
    </div>
</template>

<script>
import SubjectivePaperList from "@/views/my/components/subjectivePaperList";

export default {
    name: "subjectivePaper",
    components: {SubjectivePaperList}
}
</script>